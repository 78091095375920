@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Roboto', sans-serif;
}
.shadow-top {
  box-shadow: 0 -12px 5px -10px rgba(149, 145, 145, 0.5);
}

.shadow-bottom {
  box-shadow: 0 12px 5px -10px rgba(149, 145, 145, 0.5);
}
@layer base {
  ul,
  ol {
    list-style: revert;
    padding: 0px 1.563rem;
    margin: revert;
  }
}
@layer utilities {
  .ellipsis {
    @apply text-ellipsis whitespace-nowrap overflow-hidden;
  }
}

.phoneInput .special-label {
  left: 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #00000099;
}

.dashboard_line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.phoneInput {
  height: 100%;
}

.dashboard_box_shadow {
  box-shadow: 0px 1px 2px 0px #00000026;
}

@keyframes slideLeft {
  from {
    transform: translateX(30%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-30%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slideLeft 0.3s ease-out forwards;
}

.animate-slide-right {
  animation: slideRight 0.3s ease-out forwards;
}
